/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import EventCard from './EventCard';
import { EventDto } from '../../models/events/EventDto';
import { getAllEvents } from '../../services/eventServices';
import { appActions } from '../../store/reducers';
import { connect } from 'react-redux';
import { EventStatus } from '../../models/enums/EventStatus';
import { ErrorDto } from '../../models/ErrorDto';
import NotFound from '../404/NotFound';

interface EventsProps {
  loading?: boolean
  setError?: (error: ErrorDto) => void
  setLoading?: (loading: boolean) => void
}

const Events: React.FC<EventsProps> = ({ loading, setError, setLoading }: EventsProps) => {
  const [events, setEvents] = useState<EventDto[]>([]);

  const isLoading = loading ?? false;

  useEffect(() => {
    void getEvents();
  }, []);

  const getEvents = async (): Promise<void> => {
    setLoading!(true);
    try {
      const apiResponse = await getAllEvents();
      if (apiResponse.success) {
        setEvents(apiResponse.data);
        setError!(new ErrorDto());
      } else {
        setError!(new ErrorDto(true, 'An error occurred while fetching the events'));
      }
    } catch (error) {
      console.error('Error fetching events:', error);
      setError!(new ErrorDto(true, 'An error occurred while fetching the events'));
    }
    setLoading!(false);
  }

  const upcomingEvents = events.filter(event => event.status === EventStatus.Confirmed);
  const pastEvents = events.filter(event => event.status === EventStatus.Completed);

  return (
    <>
      {
        upcomingEvents.length !== 0 || pastEvents.length !== 0 ?
          <div className='r-container' style={{ padding: '3em 2em 6em 2em' }}>
            {
              upcomingEvents.length > 0 &&
              <>
                <div className='row' style={{ marginTop: 30 }}>
                  <h3 className="lh-1 fw-bold" style={{ color: 'white', fontSize: '48px' }}>UPCOMING EVENTS</h3>
                </div>
                <div className='row row-cols-1 row-cols-lg-4 text-start' style={{ marginTop: 10 }}>
                  {
                    upcomingEvents.map((event, index) => (
                      <EventCard key={index} event={event} showDescription={true} />
                    ))
                  }
                </div>
              </>}

            {
              pastEvents.length > 0 &&
              <>
                <div className='row' style={{ marginTop: 60 }}>
                  <h3 className="lh-1 fw-bold" style={{ color: 'white', fontSize: '48px' }}>PAST EVENTS</h3>
                </div>
                <div className='row row-cols-1 row-cols-lg-4 text-start' style={{ marginTop: 10, marginBottom: 30 }}>
                  {
                    pastEvents.map((event, index) => (
                      <EventCard key={index} event={event} showDescription={true} />
                    ))
                  }
                </div>
              </>}
          </div> : 
          isLoading ? 
            <></> :
            <NotFound message={'It looks like there are no events at the moment. Stay tuned for updates.'.toUpperCase()} />
      }
    </>
  );
}

const mapStateToProps = (state: any): any => ({
  loading: state.app.loading,
});

const mapDispatchToProps = (dispatch: any): any => ({
  setError: (error: ErrorDto) => dispatch({ type: appActions.SET_ERROR, payload: error }),
  setLoading: (loading: boolean) => dispatch({ type: appActions.SET_LOADING, payload: loading })
});

export default connect(mapStateToProps, mapDispatchToProps)(Events);
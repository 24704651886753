import './styles/Header.css'

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import * as React from 'react';
import {
    AppBar, Button, Box, CssBaseline, 
    Divider, Drawer, IconButton, List,
    ListItem, ListItemButton, ListItemText, 
    Toolbar, Typography, createTheme, ThemeProvider,
    Stack
} from '@mui/material';

import { Menu as MenuIcon } from '@mui/icons-material';
import { isNullOrEmptyOrWhitespace } from '../../utils/stringUtils';

const darkTheme = createTheme({
    palette: {
        background: {
            default: 'black', // Custom background color
            paper: 'black', // Custom background color for paper components
        }
    },
});

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
}

const drawerWidth = 240;
const navItems = ['Home', 'Events', 'About', 'Contact'];

export default function DrawerAppBar(props: Props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const location = useLocation();
    const { pathname } = location;
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const currentSection = searchParams.get('section');

    useEffect(() => {

    }, [location, searchParams]);

    const handleNavigation = (path: string) => {
        switch (path) {
            case 'HOME':
                navigate('/');
                break;
            case 'EVENTS':
                navigate('/events');
                break;
            case 'ABOUT':
                handleSections('about');
                break;
            case 'CONTACT':
                handleSections('contact');
                break;
            default:
                navigate('/');
                break;
        }
    }

    const handleSections = (path: string) => {
        if (pathname === '/') {
            setSearchParams({ ...searchParams, section: path });
        }
        else {
            navigate(`/?section=${path}`);
        }
    }

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Stack direction='column' alignItems={'center'} alignContent={'center'} spacing={2} width={'100%'} sx={{ padding: 2 }}>
                <img src="/image/logo.gif" alt="" className="img-fluid" style={{ height: 70, width: 70 }} />
            </Stack>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item} disablePadding>
                        <ListItemButton
                            className='NavButton'
                            onClick={() => handleNavigation(item.toUpperCase())}
                            sx={{ textAlign: 'center' }}>
                            <ListItemText primary={item} style={{ color: 'white' }} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    const setNavigationButtonColor = (item: string) => {
        switch (item) {
            case 'Home': {
                if (pathname === '/' && isNullOrEmptyOrWhitespace(currentSection)) {
                    return '#ff934e';
                }
                return 'white';
            }
            case 'Events': {
                if (pathname === '/events' && isNullOrEmptyOrWhitespace(currentSection)) {
                    return '#ff934e';
                }
                return 'white';
            }
            case 'About': {
                if (currentSection === 'about') {
                    return '#ff934e';
                }
                return 'white';
            }
            case 'Contact': {
                if (currentSection === 'contact') {
                    return '#ff934e';
                }
                return 'white';
            }
            default: break;
        }
    }

    return (
        <ThemeProvider theme={darkTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed"
                    style={{ backgroundColor: '#000' }}>
                    <div>
                        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', height: 70 }}>
                            <IconButton
                                style={{ color: '#ff934e' }}
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ mr: 2, display: { sm: 'none' } }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <div className='container' style={{ paddingLeft: 0, paddingRight: 0 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', height: 100 }}>
                                    <Typography
                                        variant="h6"
                                        component="div"
                                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                                    >
                                        <img src="/image/logo.gif" alt="" className="img-fluid" style={{ height: 70, width: 70 }} />
                                    </Typography>

                                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                        {navItems.map((item) => (
                                            <Button key={item}
                                                className='NavButton'
                                                sx={{ color: setNavigationButtonColor(item), fontWeight: 1000, marginLeft: 3 }}
                                                onClick={() => handleNavigation(item.toUpperCase())}>
                                                {item}
                                            </Button>
                                        ))}
                                    </Box>
                                </Box>
                            </div>
                        </Toolbar>
                    </div>
                </AppBar>
                <nav style={{ backgroundColor: '#000' }}>
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true,
                            // Better open performance on mobile.
                        }}
                        sx={{
                            height: '100%',
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        {drawer}
                    </Drawer>
                </nav>
            </Box>
        </ThemeProvider>
    );
}